// src/components/Header.js
import React from 'react';
import './Header.css'; // Importing custom styles
import { FaSearch, FaUser, FaShoppingCart } from 'react-icons/fa'; // Importing icons from react-icons
import logo from '../assets/Little Pet Express Logo.png';
import ArrowIcon from './ArrowIcon';

const Header = () => {
  return (
    <header className="header">
      <div className="logo-section">
        <img
          src={logo}
          alt="Logo"
          className="logo"
        />
      </div>

      <div className="search-bar">
        <input type="text" placeholder="Search" className="search-input" />
        <button className="search-button">
          <FaSearch />
        </button>
      </div>

      <div className="user-cart-section">
        <div className="user-option">
          <FaUser className="icon" />
          <span>Sign in <ArrowIcon /></span>
        </div>
        <div className="cart-option">
          <FaShoppingCart className="icon" />
          <span>Cart <ArrowIcon /></span>
        </div>
      </div>
    </header>
  );
};

export default Header;
