import React from 'react';
import Header from './components/Header';
import MenuBar from './components/MenuBar';
import TopBar from './components/TopBar'; // If you have a top bar
import SlideshowContainer from './components/SlideshowContainer';

const App = () => {
  return (
    <div>
      <TopBar message="Free shipping on orders over $75!" speed={20} />
      <Header />
      <MenuBar />
      <SlideshowContainer />
      {/* Other components go here */}
    </div>
  );
};

export default App;

