// src/components/SlideshowContainer.js
import React, { useState } from 'react';
import './SlideshowContainer.css'; // Import the CSS for styling
import { FaPause, FaPlay, FaChevronLeft, FaChevronRight } from 'react-icons/fa'; // Import icons
import backgroundImage from '../assets/slideshow-background.png'; // Import your background image

const SlideshowContainer = () => {
  const [isPaused, setIsPaused] = useState(false);

  // Toggle function for pause/play button
  const togglePlayPause = () => {
    setIsPaused((prev) => !prev);
  };

  // Placeholder functions for navigation
  const handlePrevious = () => {
    console.log("Previous slide");
  };

  const handleNext = () => {
    console.log("Next slide");
  };

  return (
    <div className="slideshow-wrapper">
      <div
        className="slideshow-container"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        {/* Previous Button */}
        <button className="nav-button prev-button" onClick={handlePrevious}>
          <FaChevronLeft />
        </button>

        <div className="slideshow-border">
          <div className="slide-content">
            {/* Add your slides or content here */}
          </div>
        </div>

        {/* Next Button */}
        <button className="nav-button next-button" onClick={handleNext}>
          <FaChevronRight />
        </button>
      </div>

      {/* Bottom bar placed below the slideshow container */}
      <div className="bottom-bar">
        <div className="pagination-container">
          {/* Pause/Play Button */}
          <div className="pause-play-button" onClick={togglePlayPause}>
            {isPaused ? <FaPlay /> : <FaPause />}
          </div>

          {/* Pagination Dots */}
          <div className="dot active"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
        </div>
      </div>
    </div>
  );
};

export default SlideshowContainer;
